/* You can add global styles to this file, and also import other style files */
@import "~bootstrap/dist/css/bootstrap.css";
@import "~@angular/material/prebuilt-themes/indigo-pink.css";

body {
  padding: 30px;
}

.ps_logo_green {
  color: #3babb0;
}

.ps_logo_light_blue {
  color: #124775;
}

.ps_logo_dark_blue {
  color: #212945;
}

a {
  color: #3babb0;
  text-decoration: none;
}

a:hover {
  color: #005458;
  text-decoration: none;
}

.ps_padding_20 {
  padding: 20px;
}

/* RENERGISE BTN */
.ps_renergise_btn {
  border-radius: 10px;
  background-color: #ffffff;
  border-color: #3babb0;
  color: #3babb0;
  max-width: 20em;
}

.ps_renergise_btn:hover {
  border-radius: 10px;
  background-color: #3babb0;
  border-color: #ffffff;
  color: #ffffff;
  max-width: 20em;
}

.ps_renergise_btn_sm {
  border-radius: 10px;
  background-color: #ffffff;
  border-color: #3babb0;
  color: #3babb0;
}

.ps_renergise_btn_sm:hover {
  border-radius: 10px;
  background-color: #3babb0;
  border-color: #ffffff;
  color: #ffffff;
}

/* END OF RENERGISE BTN */

/* RENERGISE BTN REVERSE */
.ps_renergise_reverse_btn {
  border-radius: 10px;
  background-color: #3babb0;
  border-color: #ffffff;
  color: #ffffff;
  max-width: 20em;
}

.ps_renergise_reverse_btn:hover {
  border-radius: 10px;
  background-color: #ffffff;
  border-color: #3babb0;
  color: #3babb0;
  max-width: 20em;
}

/* END OF RENERGISE BTN REVERSE */

/* RENERGISE BTN IN RESULT VISUALISATION */
.ap_button_centered {
  text-align: center;
}
/* END OF RENERGISE BTN IN RESULT VISUALISATION */

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

/* FORM INPUT PLACEHOLDER */
input::placeholder {
  color: #3babb0;
}

input:hover::placeholder {
  color: #fff;
}

input[type="text"]::placeholder {
  color: #3babb0;
}

input[type="text"]:hover::placeholder {
  color: #fff;
}

input[type="number"]::placeholder {
  color: #3babb0;
}

input[type="number"]:hover::placeholder {
  color: #fff;
}
input[type="password"]::placeholder {
  color: #3babb0;
}

input[type="password"]:hover::placeholder {
  color: #fff;
}
/* End of FORM INPUT PLACEHOLDER */

.mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-bar {
  background-color: #3babb0;
}

.mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-thumb {
  background-color: #3babb0;
}

.pointer {
  cursor: pointer;
}

.ps_hidden {
  display: none;
}

.ap_spacing {
  margin-bottom: 4px;
  margin-right: 4px;
  width: 8em;
}

.mat-progress-bar-buffer {
  background: #c1edef;
}

.mat-progress-bar-fill::after {
  background-color: #3babb0;
}

/* for chart dropdown menu  */
.multiselect-dropdown .dropdown-btn {
  display: inline-block;
  border: 1px solid #adadad;
  width: 100%;
  padding: 6px 6px;
  margin-bottom: 0;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.1;
  text-align: left;
  vertical-align: middle;
  cursor: pointer;
  background-image: none;
  border-radius: 4px;
}

.dropdown-list li {
  padding: 1px 1px !important;
  background: transparent;
}

.selected-item-container .selected-item {
  border: 1px solid transparent !important;
  background: transparent !important;
  padding: 0 0px !important;
  color: #3babb0 !important;
  border-radius: 8px !important;
  max-width: 100% !important;
  max-height: fit-content;
}

.dropdown-list {
  width: 100% !important;
}

.multiselect-item-checkbox {
  margin-left: 4px;
}

.multiselect-item-checkbox input+div {
  background: white !important;
  color: #3babb0 !important;
}

.multiselect-item-checkbox input+div:hover {
  background: #3babb0 !important;
  color: white !important;
}

.multiselect-item-checkbox input+div::before {
  background: white !important;
  border-color: #3babb0 !important;
}

.multiselect-item-checkbox input+div::after {
  border-color: #3babb0 !important;
}

.mat-select-panel .mat-option {
  background: #fff !important;
  color: #3baab0 !important;
  min-width: max-content;
  padding: 0px 8px;
}

.mat-select-panel .mat-option:hover {
  background: #3baab0 !important;
  color: #fff !important;
  min-width: max-content;
  padding: 0px 8px;
}

.mat-select-panel {
  min-width: max-content !important;
}

.mat-select-trigger {
  text-align: center;
  font-size: 1em;
  min-height: 24px;
}

.mat-select-value-text {
  background: #f8f9fa;
  color: #3baab0;
  position: relative;
  top: 5px;
}

/* for please select project h3 tag */
.sel_prj_h3{
  position: relative;
  text-align: justify;
  top:32%
}

.password_icon{
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  background-color: #ffffff;
  border-color: #3babb0;
  color: #3babb0;
  max-width: 20em;
  cursor: pointer;
}
.password_icon:hover{
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  background-color: #3babb0;
  border-color: #3babb0;
  color: #ffffff;
  max-width: 20em;
  cursor: pointer;
}

input.ng-invalid.ng-touched {
  border: 1px solid red;
}

/* Validation style */
/* input.ng-invalid.ng-touched{
  border: 1px solid red;
} */